import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Axios from 'axios'
import {AIOS_BASE_URL} from "@/constants.js"

import ElementUI from "element-ui"  //全局引入element
import "element-ui/lib/theme-chalk/index.css"  //全局引入element的样式

// //关闭开发模式提示
// Vue.config.devtools=false
// Vue.config.productionTip = false

Vue.use(ElementUI);//全局注入element

Vue.prototype.$http = Axios;
//添加向后端发起请求的服务器地址前缀
// Axios.defaults.baseURL="http://127.0.0.1:4523/m1/3395587-0-default/api";
Axios.defaults.baseURL=AIOS_BASE_URL;
//设置请求超时时间
Axios.defaults.timeout=5000;

//axios拦截器
//对接口request拦截
Axios.interceptors.request.use(function(config){
	
	//发起增删改查请求时，带上token认证
	var user = localStorage.getItem("user");
	if(user){
		config.headers["token"] = JSON.parse(user).token;
	}
	
	return config;
})
//携带证书 session id
Axios.defaults.withCredentials = true

//验证码地址
Vue.prototype.$verificationCodeUrl=AIOS_BASE_URL + "/createImageCode";
//aj-captcha地址前缀
// Vue.prototype.$ajBaseUrl="http://127.0.0.1/api"

// 引入安装包和样式
import WeixinEmojis from 'vue-weixin-emojis'
import 'vue-weixin-emojis/dist/vue-weixin-emojis.css'
// 将图片qqface.png放在项目中的静态文件夹中（如: /public 或 /src/statics目录下）
// url参数改成你放置的图片位置
// 获取图片URL
const emojiUrl = require('@/assets/qqface.png');
Vue.use(WeixinEmojis, {url: emojiUrl})

//引入阿里巴巴图标库
import '@/assets/icon/iconfont.css'
import '@/assets/icon/iconfont.js'

//引入图片裁剪工具
import VueCropper from 'vue-cropper'
Vue.use(VueCropper)

//json跨域工具
import {VueJsonp} from 'vue-jsonp';
Vue.use(VueJsonp);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
