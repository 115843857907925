// 读取配置文件
var request = new XMLHttpRequest();
request.open("GET", "./config.json", false);
request.send(null);
const config = JSON.parse(request.responseText);

// 根据当前环境获取对应的 IP 地址
const environment = process.env.NODE_ENV;
const baseURL = config[environment];

export const AIOS_BASE_URL = baseURL+"/api"
export const IMG_BASE_URL = baseURL+"/upload/image/"