<template>
  <div class="tag-cloud-container">
	<el-card class="card">
		<div class="header">标签</div>
		<el-scrollbar>
		  <el-tag
			v-for="tag in historyTags"
			:key="tag.id"
			class="tag-cloud-item"
			size="medium"
			effect="light"
			type="success"
			@click="handleClick(tag)"
		  >
			{{ tag.name }}
		  </el-tag>
		</el-scrollbar>
	</el-card>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tagList: ['标签1', '标签2', '标签3', '标签4', '标签5', '标签6', '标签7', '标签8'],
    };
  },
  computed:{
	  historyTags(){
		  return this.$store.state.historyTags;
	  },
  },
  methods: {
    handleClick(tag) {
      // 点击标签时的处理逻辑
      this.$router.push({
        path: "/tag",
        query: { currentPage: 1 ,tag_id:tag.id,time:Date.now()},
      });
    },
  },
};
</script>

<style scoped>
.tag-cloud-container {
  height: 200px;
  overflow: hidden; /* 超出部分隐藏 */
  text-align: left;
}

.tag-cloud-item {
  cursor: pointer;
  margin-right: 10px;
  margin-top: 10px;
  z-index: 100;
}

.header{
	background-color: #409eff;
	color: #fff;
	text-align: center;
	padding: 10px;
}

.card{
	margin-left: 10px;
	margin-right: 10px;
}
</style>