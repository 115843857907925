<template>
  <div class="container">
	
	<el-aside>
		<el-card class="card"  v-if="headerTags.length==0?false:true">
		  <ul class="list">
			<li class="li" v-for="(header,index) in headerTags" v-html="gradeText(header)" @click="scrollToAnchor(index)" @mouseover="mouseIndex=index" @mouseleave="mouseIndex=-1" :style="{ color: (activeAnchor === index)?'dodgerblue':'black' ,backgroundColor:(mouseIndex === index)?'lightsteelblue':'transparent'}">
			  选项1
			</li>
			<!-- <li>
			  选项2
			</li>
			<li>
			  选项3
			</li> -->
		  </ul>
		</el-card>
	</el-aside>	
  </div>
</template>

<script>
export default{
	data() {
		return {
			mouseIndex:-1,
		}
	},
	computed: {
	    headerTags() {
			return this.$store.state.headerTags;
	    },
		activeAnchor() {
			return this.$store.state.activeAnchor;
		}
	},
	methods:{
		//滚动到锚点
		scrollToAnchor(index) {
		  if (this.headerTags[index]) {
			const fixedBarHeight = 63; // 假设顶栏高度是50px
			window.scrollTo({
				top: this.headerTags[index].offsetTop - fixedBarHeight,
				behavior: 'smooth'
			});
		  }
		},
		gradeText(element){
			const tag = element.nodeName.toLowerCase();
			if(tag==='h1'){
				return '●'+element.textContent;
			}else if(tag==='h2'){
				return '&nbsp;●'+element.textContent;
			}else if(tag==='h3'){
				return '&nbsp;&nbsp;●'+element.textContent;
			}else if(tag==='h4'){
				return '&nbsp;&nbsp;&nbsp;●'+element.textContent;
			}else if(tag==='h5'){
				return '&nbsp;&nbsp;&nbsp;&nbsp;●'+element.textContent;
			}else if(tag==='h6'){
				return '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;●'+element.textContent;
			}
		},
	},
}
</script>

<style scoped>
	.container{
	/* 	display: flex;
		justify-content: center; */
		
	}
	.el-aside{
		position: fixed;
		top:200px;
	}
	.list{
		list-style-type: none;
		text-align: left;
		padding-left: 0px;
	}
	.card{
		margin-left: 10px;
	}
	.li{
		/* list-style-type: disc; */
		cursor: pointer;
	}
</style>