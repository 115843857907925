<template>
  <div id="app" v-loading="loadingFlag">
	<router-view/>
	<!-- <div v-if="!backgroundFlag">
			...
	</div>
	<div v-else>
		<Background></Background>
	</div> -->
  </div>
</template>

<script>
	
	import store from './store/index.js'
	
	
	export default{
		data() {
			return {
				// flag:this.$store.state.loadingFlag, 没有更新
				beforeUnloadTime:0,
			};
		},
		computed:{
			loadingFlag:()=>{
				return store.state.loadingFlag;
			},
			backgroundFlag:()=>{
				return store.state.backgroundFlag;
			},
		},
		mounted(){
			// setTimeout(()=>{    不必用，路由会触发
			// 	//页面加载完成，实例已经挂载
			// 	this.flag=false;
			// 	console.log(this.flag);
			//     },500);		
				
			// 监听beforeunload事件
			window.addEventListener('unload', this.handleUnload);
			window.addEventListener('beforeunload', this.handleBeforeUnload);
			//读取历史标签
			const historyTags = localStorage.getItem("historyTags")?JSON.parse(localStorage.getItem("historyTags")):[];
			this.$store.commit('setHistoryTags', historyTags);
			//打开网站时检查用户登录是否失效
			this.$nextTick(()=>{this.checkLogin();});
			
			//修改页签标题
			document.title = 'Xing的个人博客';
		},
		//刷新页面时触发，没反应
		// beforeRouteEnter(to,from,next){
		// 	//设置为加载中
		// 	this.flag=true;
		// 	console.log(this.flag);
		// 	next();
		// }
		destroyed() {
			// 组件销毁前移除事件监听
			window.removeEventListener('unload', this.handleUnload);
			window.removeEventListener('beforeunload', this.handleBeforeUnload);
		},
		methods:{
			//关闭页面时触发的函数
			handleUnload(event) {
				// if(this.isReload==false){
				// 	localStorage.removeItem("commentsByBlogId");
				// }else{
				// 	this.isReload=false;
				// }
				let time = new Date().getTime() - this.beforeUnloadTime;
				if (time <= 5) {
					localStorage.removeItem("commentsByBlogId"); // 关闭
				} else {
					// 刷新
				}
			},
			handleBeforeUnload(e){
				// this.isReload=true;
				this.beforeUnloadTime = new Date().getTime();
			},
			checkLogin(){
				if(localStorage.getItem("user")==null) return;
				this.$http.get("/checkLogin")
						  .then((response)=>{
							  if(response.data.code === 0){//发送成功
							  }
							  else{
								  this.$message.error(response.data.data.message);
								  this.$nextTick(()=>{
									//退出登录
									this.$store.commit('setIsLogouted', false);
									this.$store.commit('setIsLogouted', true);
								  });
							  }
						  })
						  .catch((error)=>{
							  //未接受到response的网络传输等错误
							  console.log(error);
						  });
			},
		},
	}
	
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
  /* 为固定头部留出空间 */
  padding-top: 80px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
/* 设置body边距 */
body {
	margin: 0;
}

/* 去掉下划线 */
a{
	cursor: pointer;
	text-decoration: none;
}
</style>
