<template>
	<div>
		<Header></Header>
		<!-- <nav>		
		  <router-link to="/">Home</router-link> |
		  <router-link to="/about">About</router-link>
		</nav> -->
		<el-container class="middle-container">
			<Sidebar class="left-bar"></Sidebar>
			<div class="middle"><router-view/></div>
			<Tagbar class="right-bar"></Tagbar>
		</el-container>
		<Footer></Footer>
	</div>
</template>

<script>
	import Header from "@/components/Header.vue"
	import Footer from "@/components/Footer.vue"
	import Sidebar from "@/components/Sidebar.vue"
	import Tagbar from "@/components/Tagbar.vue"
	
	export default {
		components:{
			Header,
			Footer,
			Sidebar,
			Tagbar,
		},
	}
</script>

<style scoped>
	.middle-container {
	  display: flex;
	  /* 为固定头部留出空间 */
	  padding-top: 80px;
	  /* 内容不足整个屏幕高度时,自动拉伸 */
	  min-height: 100vh;
	}
	.middle{
		flex: 6;
	}
	.left-bar{
		flex: 2;
	}
	.right-bar{
		flex: 2;
	}
</style>