<template>
  <div class="header-div">
    <el-container class="site-container">
      <el-header class="site-header">
        <!-- <el-row>
          <el-col :span="24">
            <div class="logo">
              <img src="../assets/logo.png" alt="Logo">
            </div>
          </el-col>
        </el-row> -->
        <el-row>
          <el-col :span="24">
            <el-menu class="site-header-menu" mode="horizontal" :router="true" :default-active="activeIndex">
			  <el-menu-item index="/" style="padding: 0 0;">
			        <img src="../assets/logo2.jpg" style="width: 60px;height: 60px;"/>
			  </el-menu-item>
              <el-menu-item index="/" >首页</el-menu-item>             
              <el-menu-item index="/blog" >博客</el-menu-item>
              <el-menu-item index="/tag" >分类</el-menu-item>
			  <el-menu-item index="/search" ><i class="el-icon-search" style="font-size: 25px;"></i></el-menu-item>
              <el-menu-item index="/support" style="margin-right: auto;">关于</el-menu-item>	
			  
			  <el-menu-item style="padding: 0 10px 0 10px;" index="/message">
				  <el-badge :value="badgeSum" :max="99" class="el-badge" :hidden="badgeSum==0">
					<i class="el-icon-message-solid" style="font-size: 25px;"></i>
				  </el-badge>
			  </el-menu-item>
			  <el-menu-item index="/login" style="padding: 0 5px;" v-if="this.user==null"><el-avatar :style="{background: background}" :src="avatarUrl">{{username}}</el-avatar></el-menu-item>
			  <el-menu-item index="/setting" style="padding: 0 5px;" v-if="this.user!=null"><el-avatar :style="{background: background}" :src="avatarUrl">{{username}}</el-avatar></el-menu-item>
			  <!-- <el-avatar :src="avatarUrl"></el-avatar> -->
			  <el-menu-item @click="goBackground()" index="/status" v-if="role==0" style="padding: 0 5px;">后台</el-menu-item>
			  <el-menu-item @click="logout()" index="/goHome" style="padding: 0 5px;">退出</el-menu-item>
			  
			  
            </el-menu>
          </el-col>
        </el-row>
      </el-header>
    </el-container>
  </div>
</template>

<script>
	// import avatarUrl from "@/assets/logo.png"
	import {IMG_BASE_URL} from "@/constants.js"
	
	import store from '../store/index.js'
	import {mapState} from 'vuex'
	
	export default{
		data() {
			return {
				IMG_BASE_URL,
				avatarUrl:null,
				user:null,
				username:"Login",
				role:1,
				background:'#C0C4CC',
				activeIndex:'/',
				badgeSum:0,
			}
		},
		computed:{
			...mapState({
				isLogouted:state => state.isLogouted,
			}),
		},
		watch: {
			isLogouted(newVal, oldVal) {
			    if(newVal){
					this.logout();
				}
			},
			$route() {
				this.setCurrentRoute(); //不调用也会选中对应菜单xxx
				//跳转/setting时菜单项没有跟着指示，重新刷新页面 （失效，index的响应变化感应不了）
				// if(this.activeIndex=='/setting') this.$router.go(0);
				
				//角标更新
				this.getBadge();
			},
		},
		created() {
			this.setCurrentRoute()
		},
		methods:{
			goBackground(){
				// store.commit("updateBackgroundFlag",true);
			},
			logout(){
				localStorage.removeItem("user");
				this.user = null;
				
				localStorage.removeItem("commentLikeIdList");
				
				this.checkUser();
			},
			checkUser(){ //登录后刷新页面
				this.user = JSON.parse(localStorage.getItem("user"));
							
				if(this.user != null ) {
					//修改avatar显示名字
					this.username = this.user.username;
					if(this.user.img) this.avatarUrl = IMG_BASE_URL+this.user.img;
					//后台按钮显示
					this.role = this.user.role;
					this.background = '#409eff';
				}else{
					this.username = "Login";
					this.avatarUrl = '';
					this.role = 1;
					this.background = '#C0C4CC';
					this.badgeSum = 0;
				}
			},
			setCurrentRoute() {
				this.activeIndex = this.$route.path;    //监听到当前路由状态并激活当前菜单
			},
			//获取角标数据
			getBadge(){
				this.$http.get("/getBadgeSum")
						  .then((response)=>{
							  if(response.data.code === 0){//发送成功
								//返回角标值
								this.badgeSum = response.data.data;
							  }
							  else{
								  // this.$message.error(response.data.data.message);
								  this.badgeSum = 0;
							  }
						  })
						  .catch((error)=>{
							  //未接受到response的网络传输等错误
							  console.log(error);
						  });
			},
		},
		mounted() {
			if(this.isLogouted) this.logout();
			this.checkUser();
			this.getBadge();
		},
	}
</script>

<style scoped>
	.header-div{
		/* 滚动时头部固定最上面 */
		position: fixed;
		top:0;
		left: 0;
		right: 0;
		
		z-index: 100;
	}
	
  .site-container {
    height: 100%;
	
	
  }

  .site-header {
    background-color: #fff;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12);
    padding: 0 15px 0 0;
  }

  .logo {
    width: 120px;
    height: 40px;
    margin: 10px 0;
  }

  .site-header-menu {
    margin: 0;
    border-bottom: 0;
	
 	display: flex; /* flex父容器 */
	justify-content: flex-end; /* 靠右对齐 ，单独设置子元素靠左：style="margin-right: auto;"*/
	/* justify-content: center; 水平居中 */
	align-items: center; /* 垂直居中 */ 
  }

  .site-header-menu .el-menu-item {
    padding: 0 15px;
  }

  .site-header-menu .el-menu-item:hover {
    background-color: #f0f2f5;
  }
  
  .el-avatar {
  }
  i{
	  cursor: pointer;
  }
  /* 修改角标位置、设置置顶 */
  :deep(.el-badge__content.is-fixed){
	  transform: translateY(20%) translateX(75%);
	  /* position: absolute; */
	  z-index: 10;
  }
  /* 修改角标大小 */
  :deep(.el-badge__content){
	  padding: 0 2px;
  }
</style>