<template>
	<div>
		<el-card class="card" v-for="blog in blogList" :key="blog.id" v-if="blog">
			<template #header>
				<h3 v-text="blog.title">标题</h3>
				<div>
					<span>创建时间：{{blog.date}}</span><el-divider direction="vertical"></el-divider>
					<span>浏览量：{{blog.viewnum}}</span><el-divider direction="vertical"></el-divider>
					<span>评论数：{{blog.commentnum}}</span>
				</div>
			</template>
			<div class="content" v-text="blog.content">
				百科全书（英语：Encyclopaedia，也作Encyclopedia；汉语拼音：bǎi kē quán shū；注音：ㄅㄞˇ ㄎㄜ ㄑㄩㄢˊ ㄕㄨ；闽东语罗马拼音：ba kow zu'owng ts'ju），是指一种大型参考书。采用词典的形式编排，收各科专门名词、术语，分列条目，详细解说，比较完备的介绍文化科学知识。有包罗万象的综合性的百科全书，也有专科性的百科全书，如医学百科全书、工程技术百科全书等。
				百科全书是对人类过去积累的全部知识或某一类知识的书面摘要。
				类型历史
				古希腊学者罗纳尔多曾编写过全面讲述当时学问的讲义，被西方奉为“百科全书之魔”，中国汉初的《尔雅》，是中国百科全书性质著作的渊源。中文“百科全书”一词是20世纪初才出现的。
				近现代百科全书的奠基者是法国学者德尼·狄德罗，以他为首的法国百科全书派于1751～1772年编纂出版了《百科全书，或科学、艺术和手工艺分类字典（Encyclopédie, ou dictionnaire raisonné des sciences, des arts et des métiers）》。
				18世纪～20世纪，英、德、法、意、苏、日，西等国相继编纂出版了一批权威性的百科全书，如《那不勒斯百科全书》 《华盛顿百科全书》《莫斯科大百科全书》《冷战时期百科事典》等。西方现代百科全书大多按字顺编排，突出工具书的检索功能，并采用小条目主义的编纂思想。注重百科全书教育功能的则采用大条目主义。修订的方式有再版制、补卷制、出版年鉴和连续修订制4种。
			</div>
			<div style="text-align: right;margin-top: 5px;"><a @click="detail(blog.id)">详细>></a></div>
		</el-card>
		<!-- <el-card class="card">
			<template #header>
				<h3>标题</h3>
				<div>
					<span>创建时间：2023-10-30</span><el-divider direction="vertical"></el-divider>
					<span>浏览量：10</span>
				</div>
			</template>
			<div class="content">
				百科全书（英语：Encyclopaedia，也作Encyclopedia；汉语拼音：bǎi kē quán shū；注音：ㄅㄞˇ ㄎㄜ ㄑㄩㄢˊ ㄕㄨ；闽东语罗马拼音：ba kow zu'owng ts'ju），是指一种大型参考书。
			</div>
			<div style="text-align: right;margin-top: 5px;"><a>详细>></a></div>
		</el-card>
		<el-card class="card">
			<template #header>
				<h3>标题</h3>
				<div>
					<span>创建时间：2023-10-30</span><el-divider direction="vertical"></el-divider>
					<span>浏览量：10</span>
				</div>
			</template>
			<div class="content">
				百科全书（英语：Encyclopaedia，也作Encyclopedia；汉语拼音：bǎi kē quán shū；注音：ㄅㄞˇ ㄎㄜ ㄑㄩㄢˊ ㄕㄨ；闽东语罗马拼音：ba kow zu'owng ts'ju），是指一种大型参考书。
			</div>
			<div style="text-align: right;margin-top: 5px;"><a>详细>></a></div>
		</el-card> -->
		<el-button class="button" type="primary" round @click="loadMore()"  :disabled="isDisabled">查看更多</el-button>
	</div>
</template>

<script>
	export default{
		data() {
			return{
				isDisabled:false,
				blogList:[
					{
						id:0,
						title:'dfdf',
						date:'',
						viewnum:'',
						commentnum:'',
						content:'',
					},
					{
						id:1,
						title:'fdgb',
						date:'',
						viewnum:'',
						commentnum:'',
						content:'',
					},
					{
						id:2,
						title:'bvx',
						date:'',
						viewnum:'',
						commentnum:'',
						content:'',
					},
				],
				moreBlogList:[
					{
						id:3,
						title:'bsdfdsvx',
						date:'',
						viewnum:'',
						commentnum:'',
						content:'',
					},
					{
						id:4,
						title:'mhgmg',
						date:'',
						viewnum:'',
						commentnum:'',
						content:'',
					},
					{
						id:5,
						title:'xzcvxzfg',
						date:'',
						viewnum:'',
						commentnum:'',
						content:'',
					}
				],
			}
		},
		mounted() {
			//查询最近三条博客			
			this.findNextThreeBlog(-1);
			this.blogList.splice(0, 3); // 清空数组的前三个元素
		},
		methods:{
			loadMore(){
				//查询更多的三条博客
				this.findNextThreeBlog(this.blogList[this.blogList.length-1].id);
				
				// this.blogList = this.blogList.concat(this.moreBlogList);
			},
			findNextThreeBlog(currentId){
				this.$http.get("/findNextThreeBlog",
												{
												params:{id:currentId},
												}
											)
											.then((response)=>{
												if(response.data.code === 0){//发送成功
													if(response.data.data.length==0) {this.$message.error("没有更多内容了！！！");this.isDisabled=true;}
													else {
														this.$message({
														  message: '查询成功！！！',
														  type: 'success',
														});
														//填充数据
														// console.log(response.data.data);
														this.blogList = this.blogList.concat(response.data.data);
														//如果有空数据，禁用按钮
														if(response.data.data.length<3)
														this.isDisabled=true;
													}
												}
												else{
													this.$message.error(response.data.data.message);
													this.isDisabled=true;
												}
											})
											.catch((error)=>{
												//未接受到response的网络传输等错误
												console.log(error);
											});
			},
			detail(val){
				this.$router.push({
				  path: "/blog",
				  query: { id:  val},
				});
			},			
		}
	}
</script>

<style scoped>
	.card{
		margin-bottom: 20px;
		text-align: left;	
	}
	.content{
		height: 85px;
		overflow: hidden;		
	}
	.button{
		margin-bottom: 20px;
	}
	/* ::v-deep .el-card__header{
		background-color: #67c23a;
	}
	::v-deep .el-card__body{
		background-color: #e1f3d8;
	} */
</style>