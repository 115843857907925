import Vue from 'vue'
import VueRouter from 'vue-router'

import HomeView from '../views/HomeView.vue'
import Home from '../views/Home.vue'
import ForegroundLayout from "../views/ForegroundLayout.vue"

import store from "../store/index.js"

//路由跳转next报错时添加如下代码
// 在VueRouter上配置路由跳转，在router中的index.js中加上以下代码，注意加在use之前
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function (location) {
    return routerPush.call(this, location).catch(err => {})
};

Vue.use(VueRouter)

const routes = [
	//前台界面
  {
    path: '/',
	component:ForegroundLayout,
	
	children:[
	  {
		  path: '/',
		  name: 'home',
		  // component: HomeView
		  component:Home,
	  },
	  {
	  		  path:"/blog",
	  		  name:"blog",
	  		  component:()=>import("../components/Blog.vue")
	  },
	  {
	  		  path:"/tag",
	  		  name:"tag",
			  component:()=>import("../components/Tag.vue")
	  },
	  {
	  		  path:"/search",
	  		  name:"search",
	  		  component:()=>import("../components/Search.vue")
	  },
	  {
	  		  path:"/support",
	  		  name:"support",
	  		  component:()=>import("../components/Support.vue")
	  },
	  {
	  		  path:"/message",
	  		  name:"message",
	  		  component:()=>import("../components/Message.vue")
	  },
	  {
		  path:"/setting",
		  name:"setting",
		  component:()=>import("../components/Setting.vue")
	  },
	]
  },
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  },
  {
	  path:'/goHome',
	  redirect:'/',
  },

  //登录界面
  {
	  path:"/login",
	  name:"login",
	  component:()=>import("../views/Login.vue")
  },
  {
  	  path:"/register",
  	  name:"register",
  	  component:()=>import("../views/Register.vue")
  },
  
  //后台界面
  {
	  path:"/background",
	  name:"background",
	  component:()=>import("../views/Background.vue"),
	  meta:{requiresAuth:true},
	  children:[
		  {
		  	  path:"/status",
		  	  name:"status",
		  	  component:()=>import("../components/SiteStatus.vue"),
		  	  meta:{requiresAuth:true},
		  },
		  {
		  	  path:"/addBlog",
		  	  name:"addBlog",
		  	  component:()=>import("../components/AddBlog.vue"),
			  meta:{requiresAuth:true},
		  },
		  {
		  	  path:"/doBlog",
		  	  name:"doBlog",
		  	  component:()=>import("../components/DoBlog.vue"),
			  meta:{requiresAuth:true},
		  },
		  {
		  	  path:"/editBlog",
		  	  name:"editBlog",
		  	  component:()=>import("../components/EditBlog.vue"),
		  			  meta:{requiresAuth:true},
		  },
		  {
		  	  path:"/managerTag",
		  	  name:"managerTag",
		  	  component:()=>import("../components/ManagerTag.vue"),
		  	  meta:{requiresAuth:true},
		  },
		  {
		  	  path:"/managerBlack",
		  	  name:"managerBlack",
		  	  component:()=>import("../components/ManagerBlack.vue"),
		  	  meta:{requiresAuth:true},
		  },
	  ]
  },
]



const router = new VueRouter({
	//取消url中的#号
	mode:"history",	
  routes
})

router.beforeEach((to,from,next)=>{
	//如果是md页内链接“#xxx”，则不跳转
	const hash = window.location.hash;
	if(hash.startsWith('#')) {
		next(false);
	}
	else{
		// console.log("路由触发");
		//路由跳转刷新触发 加载特效 通过控制App.vue v-loading="loadingFlag"的值实现
		store.commit("updateLoadingFlag",true);
		
		  if (to.meta.requiresAuth) {
			// 获取 JWT Token
			// const token = getJwtToken();
			// 判断角色是否为管理员
			// if (token.role === 2) {
			//   next();
			// } else {
			//   alert('您没有权限访问此页面，请先登录。');
			//   next('/home');
			// }
			//要求需要权限的另外处理
			
			var user = JSON.parse(localStorage.getItem("user"));
			if(!user){ //没有登录
				next("/login");
				
				//刷新加载完成
				setTimeout(()=>{	
					store.commit("updateLoadingFlag",false);
				},500);	
			}
			else if(user.role === 0){ next(); }//登录成功，管理员账号
			else{//登录成功，普通账号
				next("/");
				
				//刷新加载完成
				setTimeout(()=>{	
					store.commit("updateLoadingFlag",false);
				},500);	
			}		
		  } else {
			next();
		  }
	}
	 
	 // if(to.path != "/"){
		 
		//  // next({path:'/'});
		//  // router.push("/").catch(()=>{});
		//  next("/");
		//  store.commit("updateLoadingFlag",false);
	 // }
	 // else{
		//  next();
	 // }
});
router.afterEach((to,from)=>{
	setTimeout(()=>{
		//路由跳转完成触发		
		store.commit("updateLoadingFlag",false);
		
		//页面跳转后始终回到最上面
		window.scrollTo(0, 0);
	},500);	
});

export default router
