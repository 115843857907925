import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
	  loadingFlag:true,
	  backgroundFlag:false,
	  headerTags:[],//文章目录
	  activeAnchor:-1,//活动锚点
	  commentnum:0 ,//评论数更新
	  historyTags:[],//历史标签
	  isLogouted:false,//判断是否登出
  },
  getters: {
  },
  mutations: {
	  updateLoadingFlag(state,newFlag){
		  state.loadingFlag=newFlag;
	  },
	  updateBackgroundFlag(state,newFlag){
		  state.backgroundFlag=newFlag;
	  },
	  setHeaderTags(state, newData) {
		state.headerTags = newData;
	  },
	  setActiveAnchor(state, newData) {
		state.activeAnchor = newData;
	  },
	  setCommentnum(state, newData) {
	  		state.commentnum = newData;
	  },
	  setHistoryTags(state, newData) {
	  		state.historyTags = newData;
	  },
	  setIsLogouted(state, newData) {
	  		state.isLogouted = newData;
	  },
  },
  actions: {
  },
  modules: {
  }
})
